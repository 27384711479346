<template>
  <v-row class="mx-auto">
    <v-col
      v-for="(pacote, index) in packagesSelect"
      :key="index"
      cols="12"
      md="6"
    >
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="index">
          <v-row>
            <v-col cols="12" lg="8">
              {{ pacote.descricao }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <date-picker
                  v-if="!loading"
                  v-model="pacote.dt_ativacao"
                  value-type="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  :editable="false"
                  placeholder="Data de ativação"
                  :disabled="loading"
                  title="Data de ativação"
                  :close="
                  pacote.dt_ativacao
                    ? (pacote.showErrorMessage = false)
                    : (pacote.showErrorMessage = true)
                "
                  @change="setFieldValue(index, pacote)"
              ></date-picker>
            </v-col>
            <v-col cols="6">
              <CurrencyInput
                  v-if="!loading"
                  :label="'Valor'"
                  :options="{ currency: 'BRL' }"
                  :value="formatCurrency(pacote, index)"
                  :index="index"
                  @changeCurrencyValue="changeCurrency"
              ></CurrencyInput>
            </v-col>
          </v-row>
          <v-col cols="12" lg="12">
            <div v-if="loading">
              <v-skeleton-loader type="heading"></v-skeleton-loader>
            </div>
            <LineComponent
              v-show="!loading"
              :readonly="true"
              :selectedLine="pacote.line"
            ></LineComponent>

            <v-alert
              border="left"
              class="text-center"
              dense
              type="warning"
              v-if="loading"
              dark
              >Carregando pacotes da linha!
            </v-alert>
            <template v-if="pacote.packages && !loading">
              <v-alert border="left" color="indigo" dark dense class="mb-0">
                Selecione os pacotes que deseja desativar:
              </v-alert>
              <v-list dense>
                <template v-for="(linePackage, index3) in pacote.packages">
                  <v-list-item :key="linePackage.nome" dense>
                    <v-list-item-content>
                      <v-row class="align-center" dense>
                        <v-col md="8">
                          {{ linePackage.nome }}
                        </v-col>
                        <v-col class="d-flex flex-row-reverse">
                          <v-switch
                            class="mt-0"
                            dense
                            v-model="linePackage.desativar"
                            hide-details
                            inset
                            @change="setFieldValue(index, pacote)"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row v-if="linePackage.desativar" dense>
                        <v-col md="12">
                          <v-text-field
                            label="Data de desativação"
                            outlined
                            dense
                            :value="pacote.dt_ativacao"
                            readonly
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    v-if="index3 < pacote.packages.length - 1"
                    :key="index3"
                  ></v-divider>
                </template>
              </v-list>
            </template>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import "vue-currency-input";

import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import CurrencyInput from "../currency/CurrencyInput.vue";
import LineComponent from "@/components/forms/LineComponent.vue";

export default {
  components: { LineComponent, CurrencyInput, DatePicker },
  props: ["pacotes", "page"],
  data() {
    return {
      packagesSelect: [],
      loading: true,
      switchIsValid: false,
      dateIsValid: false,
      saveValid: false,
      today: new Date()
    };
  },

  async mounted() {
    this.packagesSelect = this.pacotes.data;
    try {
      await Promise.all([
        this.loadingFilters()
      ]);
      this.$store.commit("solicitation/SET_SOLICITATION_VALIDATE_PACKAGES_VALUE", this.pacotes.data);
      if (this.page === 'interaction') {
        this.setAutomationComponentFields({
          automation: "busca_pacote",
          data: this.pacotes
        });
      }
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
    this.loading = false;
  },

  computed: {
    ...mapState("packages", ["packages", "lines", "packageTypes"]),
    ...mapGetters({
      name: "forms/getItemName",
      linePackages: "packages/getLinePackages",
    })
  },

  methods: {
    ...mapActions("interaction", ["updateFieldsPackages","setAutomationComponentFields"]),
    ...mapActions("solicitation", ["updateSolicitationValidatePackagesValues"]),
    ...mapActions("packages", ["loadingFilters", "loadPackages"]),
    ...mapMutations({ setSave: "interaction/setSave" }),

    setFieldValue(index, pacote) {
      this.validateSave(pacote);
      this.updateValuesToSend(index, pacote);
    },
    changeCurrency(index, value) {
      this.$store.commit("solicitation/SET_SOLICITATION_VALIDATE_PACKAGES_VALUES_VALOR_PACOTE", {index, valor_pacote: value});
    },
    async updateValuesToSend(index, pacote) {
      if (this.page !== "interaction") {
        await this.updateSolicitationValidatePackagesValues({
          fields: pacote,
          index
        });
      } else {
        this.setAutomationComponentFields({
          automation: "busca_pacote",
          data: this.pacotes
        });
      }
    },
    formatCurrency(pacote, index) {
      let valor = '0';
      if (pacote.linha_referencia.valor_assinatura) {
        valor = pacote.linha_referencia.valor_assinatura.replace('.',',');
        return 'R$ '+valor;
      }
      return '';
    },
    validateSave(pacote) {
      pacote.packages.map(e => {
        if (e.desativar == true) {
          this.switchIsValid = true;
        }
      });

      if (pacote.dt_ativacao != null) {
        this.dateIsValid = true;
      }

      if (this.switchIsValid == true && this.dateIsValid == true) {
        this.saveValid = true;
        this.setSave(this.saveValid);
        return this.saveValid;
      }
    }
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
